.hero {
	// margin-top: -90px !important;
	.section-inner {
		// padding-top: $hero--padding-t__mobile;
		// padding-bottom: $hero--padding-b__mobile;
		padding-top: 0px !important;
	}
}

.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		// margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

.hero-title {
	font-family: Jersey;
	color: $yellow;
	letter-spacing: 1px;
	font-size: 42px;
}

.hero-desc {
	font-family: pfpro;
    color: white;
    font-size: 18px;
    opacity: 0.5;
    text-align: center;
    width: 100%;
    padding: 0px 15%;
    margin-right: 0px !important;
}


@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			// padding-top: $hero--padding-t__desktop;
			// padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
