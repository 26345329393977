.about-section {
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 5rem;
  font-family: pfpro;
}

.about-section .fw2 {
  font-weight: 200;
}

.about-section .lh-copy {
  line-height: 1;
}

.about-section .black-90 {
  color: rgba(255, 255, 255, 0.9);
}

.about-section .black-50 {
  color: rgba(255, 255, 255, 0.75);
}

.about-section .ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.about-section .mt0 {
  margin-top: 0;
}

.about-section .mv3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.about-section .tc {
  text-align: center;
}

.about-section .f3 {
  font-size: 2.75rem;
}

.about-section .f5 {
  font-size: 1.5rem;
}

.about-section .text {
  text-align: left;
  margin: 0 auto;
  line-height: 1.5;

  padding-bottom: 2rem;
  font-size: 1rem;
  max-width: 80em;
}

.about-section ul {
  text-align: left;
}

.about-section .gridBox {
  text-align: center;
  margin: 0 auto;
}

.about-section .block {
  width: 350px;
  height: 350px;
  margin: 3px;
  display: inline-block;
  background: #d9c57e;
  word-wrap: break-word;
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: middle;
  line-height: 25px;
  color: #ffffff;
}

.about-section .carouselItem {
  width: 100%;
  padding: 50px;
  height: 500px;
}

.about-section .flexBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section .flexItem {
  justify-content: center;
  align-items: center;
  flex: 1;
}

.about-section .flexItem .text {
  padding-left: 0px;
}

.about-section .flexImageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.about-section .simpleLI {
  list-style-type: none;
}

.announcement {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 100px);
}

@media screen and (max-width: 80em) {
  .about-section {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .about-section .text {
    font-size: 0.85rem;
  }

  .about-section .f3 {
    font-size: 1.75rem;
  }

  .about-section .f5 {
    font-size: 1rem;
  }

  .about-section .block {
    font-size: 0.75rem;
    width: 300px;
    height: 300px;
    line-height: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .about-section .flexBox {
    display: inline-block;
  }

  .about-section .flexItem {
    margin-top: 2.5rem;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .about-section .flexImageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .about-section ul {
    font-size: 0.85rem;
  }
}
