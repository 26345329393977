.features-split {

	background-color: #22222A;
	.section-inner {
		padding-top: $features-split--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $features-split-header--padding__mobile;
	}
}

@include media( '>medium' ) {

	.features-split {

		.section-inner {
			padding-top: $features-split--padding-t__desktop;
			padding-bottom: $features-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $features-split-header--padding__desktop;
			h2 {
				font-family: Jersey;
				color: #D9C57E;
				letter-spacing: 1px;
				font-size: 42px;
			}
			p {
				font-family: pfpro;
				color: white;
				font-size: 18px;
				opacity: 0.5;
				text-align: center;
				width: 100%;
				padding: 0px 15%;
				margin-right: 0px !important;
			}
		}
		.split-wrap {
			.split-item {
				.split-item-content {
					ul {
						li {
							margin-bottom: 0px !important;
						}
					}
				}
				h3 {
					font-family: pfpro;
					color: #D9C57E;
					letter-spacing: 1px;
					font-size: 32px;
				}
				p {
					font-family: pfpro;
					color: #ffffff;
					letter-spacing: 1px;
					font-size: 16px;
					opacity: 0.5;
				}
				a.button-dark {
					background-color: #D9C57E;
					border-radius:5px;
					color: #212129;
					font-family: jersey;
					letter-spacing: 0px;
					font-weight: 300;
					transition: 0.1s ease-out;
					&:hover {
						color: #D9C57E;
						background-color: #212129;
					}
				}
			}
		}
	}
}
