.fullscreen-section .dib {
    display: inline-block;
}
.fullscreen-section {
    position: relative;
    height:100vh;
    display: flex;
    padding-top:75px;
}
.fullscreen-section img.homedrives {
    margin-left: 5%;
    width:90%;
}
.fullscreen-section h2 {
    font-family: jersey;
}
.fullscreen-section .bg-circle {
    position: absolute;
    top:125px;
    right:-15%;
    width:70%;
    z-index: -99;
}
.fullscreen-section .scrollDown {
    position: absolute;
    bottom: 25px;
    width: 25px;
    z-index: 1;
}
.fullscreen-section .items-center {
    align-items: center;
}

.fullscreen-section .fw1 {
    font-weight: 100;
}

.fullscreen-section .fw2 {
    font-weight: 200;
}

.fullscreen-section .vh-100 {
    height: 100vh;
}

.fullscreen-section .lh-title {
    margin-bottom: 10px !important;
    font-family: pfpro;
    font-weight: 400;
    font-size: 42px;
}
.fullscreen-section .lh-subtitle {
    margin-top: 0px !important;
    margin-bottom: 45px !important;
    font-family: pfpro;
    font-weight: 400;
    font-size: 28px;
}
.fullscreen-section .mw6 {
    max-width: 32rem;
}

.fullscreen-section .ph3-right {
    padding-right: 0.5rem;
}

.fullscreen-section .ph3-left {
    padding-left: 0.5rem;
}

.fullscreen-section .ph5 {
    padding-left: 10rem;
    padding-right: 10rem;
}

.fullscreen-section .mb3 {
    margin-bottom: 1rem;
}

.fullscreen-section .mb4 {
    margin-bottom: 2rem;
}

.fullscreen-section .mt4 {
    margin-top: 2rem;
}

.fullscreen-section .tc {
    text-align: left;
}

.fullscreen-section .f3 {
    font-size: 1.5rem;
}

.fullscreen-section .f5 {
    font-size: 1rem;
}

.fullscreen-section .grow {
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform .25s ease-out;
}

.fullscreen-section .grow:hover, .grow:focus {
    transform: scale(1.05);
}

.fullscreen-section .grow:active {
    transform: scale(.9);
}

.fullscreen-section .flex-ns {
    display: flex;
    /* background-image: url('./../../assets/images/driveMainHeroBG.png'); */
    background-size: cover;
}

.fullscreen-section .flex-ns div:nth-child(5n){
    flex:1;
}

.fullscreen-section .flex-ns div:nth-child(3n){
    flex:1;
}

.fullscreen-section .mt0-ns {
    margin-top: 0;
}

.fullscreen-section .f1-l {
    margin-top: 1em;
    font-size: 2.5rem;
}

.fullscreen-section .f3-l {
    font-size: 1.5rem;
}

.fullscreen-section .bigCenter {
    text-align: center;
    padding: 20rem;
}

.fullscreen-section .download-buttons {
    display: flex;
    align-items: center;
}


.fullscreen-section section div {
    flex:1
}

.fullscreen-section.container {
    max-width: 85% !important;
}

.fullscreen-section section .tc.tl-ns {
    text-align: center !important;
}

.fullscreen-section section .tc.tl-ns .download-buttons {
    display: flex;
    justify-content: center;
}
.spacer-right {
    padding-right: 15%;
}
.spacer-bottom {
    padding-bottom: 10%;
}

/* Mobile Styling */
@media screen and (max-width: 40em) {
    .fullscreen-section .mb5-l {
        margin-bottom: 1rem;
    }

    .fullscreen-section .f1-l {
        margin-top: 2em;
        font-size: 2rem;
    }

    .fullscreen-section .f3-l {
        font-size: 1rem;
    }

    .fullscreen-section .ph5 {
        padding-left: 1em;
        padding-right: 1em;
    }

    .fullscreen-section .tc {
       text-align: center;
    }

    .fullscreen-section .download-buttons {
        justify-content: center;
    }

    .spacer-right {
        padding-right: 0%;
    }
    .spacer-bottom {
        padding-bottom: 0%;
    }
}
