.cta {

    .form-input {
        border-color: transparent;
        border-radius: 2px;
        color: #e1e1e1;
    }
}

.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
	background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;    
}
.ctaWrapper {
    .cta-slogan {
        h3 {
            font-family: jersey;
            color: $primary-dark;
        }
    }
    background-color: $yellow;
    color:#e1e1e1
}
@include media( '>medium' ) {

.cta {

    .form-input {
        min-width: 280px;
    }
}    

	.cta-inner {
		padding-left: 48px;
        padding-right: 48px;
        display: flex;
        flex-direction: column;
        .cta-action {
            margin-top:25px;
            input {
                width:350px !important;
                border-radius: 5px;
                background-color: $primary-dark;
            }
            svg {
                path {
                    fill:$yellow !important;
                }
            }
        }
	}
}
